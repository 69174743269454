import { createApp } from 'vue';

import router from './middlewares/router';
import store from './middlewares/store';
import App from './components/App.vue';
import titleMixin from './utils/titleMixin';
import utilMixin from './utils/utilMixin';
import { createMetaManager } from 'vue-meta';


const app = createApp(App);
app.use(router);
app.use(store);
app.use(createMetaManager());
app.mixin(titleMixin);
app.mixin(utilMixin);

app.mount('#app');
