<template>
    <div class="pagination-container">
        <span>Pages: </span>
        <router-link v-for="pageNo in pageCount" :key="pageNo" 
            :class="`${ currentPage == pageNo ? 'active' : '' }`" 
            :to="toUrl(pageNo)">{{ pageNo }}</router-link>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['filteredSongs'],
    inject: ['category'],
    computed: {
        ...mapState(['data', 'currentLanguage', 'currentAlphabet', 'currentPage']),

        pageCount() {
            if (this.data.songs) {
                return Math.ceil(this.filteredSongs.length / 100);
            }
            return 1;
        },

        isVideoPage() { return this.$route.path.indexOf('video-songs') !== -1; },

        isList() { return this.$route.path.indexOf('list') !== -1; },

        category() {
            const path = this.$route.path;
            if (path.indexOf('albums') != -1) {
                return 'albums';
            } else if (path.indexOf('artists') != -1) {
                return 'artists';
            } else if (path.indexOf('genres') != -1) {
                return 'genres';
            } else if (path.indexOf('search') != -1) {
                return 'search'
            }
            return null;
        },
    },
    methods: {
        toUrl(pageNo) {
            if (this.category) {
                if (this.category === 'search') {
                    return `/${ this.currentLanguage }/${this. category}/${ this.$route.params.keyword }/${ pageNo }`;    
                }
                return `/${ this.currentLanguage }/${this. category}/${ this.$route.params.cid }/${ pageNo }`;
            } else if (this.isList) {
                const listId  = this.$route.params.listId;
                const s = this.$route.query.s;
                const t = this.$route.query.t;
                return `/${this.currentLanguage}/list/${listId}/${this.currentPage}?s=${s}&t=${t}`;
            }
            return this.isVideoPage ?
                `/${ this.currentLanguage }/video-songs/${ this.currentAlphabet }/${ pageNo }`:
                `/${ this.currentLanguage }/${ this.currentAlphabet }/${ pageNo }`;
        }
    }
})
</script>

<style scoped>
.pagination-container a {
    border-radius: 20% !important;
    border-color: #ed516f;
}
.pagination-container a.active, .pagination-container a:hover {
    background: #ed516f;
    color: #f6f6f6;
}
</style>
