<template>
    <div v-for="song in songs" :key="song.id" class="category-item media-item arrow-parent" 
        :class="`${ currentId ? 'col-6' : 'col-3' }`">
        <router-link :to="`/${currentLanguage}/karaoke-songs/${song.id}`"
            class="data-category-name data-media-name arrow-link ellipsis">
            <div class="ms_rcnt_box marger_bottom30">
                <div class="ms_rcnt_box_img">
                    <img :src="`https://img.youtube.com/vi/${song.karoke}/0.jpg`" :alt="song.title"
                        class="img-fluid data-category-img data-media-img" :title="song.title">
                    <div class="ms_main_overlay">
                        <div class="ms_box_overlay"></div>
                        <div class="ms_play_icon">
                            <img src="/images/svg/play.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="ms_rcnt_box_text">
                    <h3>{{ song.title }}</h3>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['songs'],
    computed: mapState(['currentLanguage', 'currentId'])
})
</script>
