<template>
    <page-template>
        <component :is="featuredComponent" v-show="featuredData" :title="featuredTitle" :type="type">
            <swiper-view :data="featuredData" :type="type" />
        </component>
        <category-item :data="categoryData" :title="title" :type="type" />
    </page-template>
</template>

<script>
import { mapState } from 'vuex';
import PageTemplate from '../layouts/PageTemplate.vue';
import FeaturedCategory from '../featured/FeaturedCategory.vue';
import SwiperView from '../layouts/SwiperView.vue';
import CategoryItem from '../layouts/CategoryItem.vue';

export default ({
    props: ['language', 'cid', 'id', 'page'],
    components: {
        PageTemplate, FeaturedCategory,
        SwiperView, CategoryItem
    },
    watch: {
        language() { this.handleLanguageChange(); },
        data() { this.handleCategoryData(); },
        cid() { this.handleCategoryChange(); },
        id() { this.handleSongChange(); },
        page() { this.handlePageChange(); },
        '$route'() { this.handleCategoryData(); }
    },
    computed: mapState(['data', 'featuredGenres']),
    data() {
        return {
            type: '', title: '', categoryData: null, 
            featuredTitle: '', featuredComponent: 'featured-category', 
            featuredData: null,
        }
    },
    methods: {
        
    },
    mounted() {
        this.handleCategoryData();
        !this.data.songs && this.handleLanguageChange();
        this.cid && this.handleCategoryChange();
        if (this.id) {
            this.handleSongChange();
            this.fetchSong(this.id);
        }
    }
})
</script>
