<template>
    <div class="ms_top_artist">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h2>Karaoke Songs ({{ karaokeSongs.length }})</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="karaoke-cont" :class="{ 'col-6': currentId }">
                    <karaoke-item :songs="karaokeSongs"></karaoke-item>
                </div>
                <div class="col-6" v-if="currentId" style="background: #efefef;">
                    <div class="ms_rcnt_box_text"><h3>{{ songTitle }}</h3></div>
                    <iframe width="560" height="315" frameborder="0" allowfullscreen style="margin-top: 10px;"
                        :src="`https://www.youtube.com/embed/${ videoId }`"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import KaraokeItem from './KaraokeItem.vue';

export default ({
    props: [],
    components: {
        KaraokeItem
    },
    computed: {
        ...mapState(['data', 'currentLanguage', 'currentId']),

        karaokeSongs() {
            if (this.data.songs) {
                return this.data.songs.filter(song => {
                    return song.karoke && song.karoke !== '';
                });
            }
            return [];
        },

        song() {
            return this.karaokeSongs.find(item => item.id == this.currentId);
        },

        videoId() {
            return this.song ? this.song.karoke : '';
        },

        songTitle() {
            return this.song ? this.song.title : '';
        }
    }
})
</script>

<style scoped>
.karaoke-cont {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 175px);
    overflow: auto;
}
</style>
