<template>
    <div class="col-lg-2 col-sm-4 col-6 arrow-parent">
        <router-link :to="`/${language}`">
            <div class="ms_genres_box">
                <img :src="`${$store.state.imageCDN}cs/languages/${language}.webp`" alt class="img-fluid">
                <div class="ms_main_overlay">
                    <div class="ms_box_overlay"></div>
                    <div class="ms_play_icon">
                        <router-link :to="`/${language}`">
                            <img src="/images/svg/open.svg" alt>
                        </router-link>
                    </div>
                    <div class="ovrly_text_div">
                        <span class="ovrly_text1"><a href="#" class="arrow-link">{{ language }}</a></span>
                    </div>
                </div>
                <div class="ms_box_overlay_on">
                    <div class="ovrly_text_div">
                        <span class="ovrly_text1"><a href="#" class="arrow-link">{{ language }}</a></span>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default ({
    props: ['language']
})
</script>

