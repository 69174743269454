<template>
    <div class="row menu-topbar">
        <div class="col-sm-3 col-3">
            <a href="#" class="overlay-back" @click="hideOverlay">
                <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                <span class="hide-on-small-screen"> Back</span>
            </a>
        </div>
        <div class="col-sm-9 col-9 format-options">
            <h5 class="song-title" :title="songTitle">{{ songTitle }}</h5>
            <div class="overlay-buttons" style="margin-right: 20px;">
                <button @click="copyUrl" class="btn btn-info" style="margin-right: 10px;"><i class="far fa-copy"></i> Copy URL</button>
                <a :href="pptUrl" target="_blank" class="btn btn-info" style="margin-right: 10px;"><i
                        class="fas fa-file-download"></i> PPT</a>
                <a :href="pdfUrl" target="_blank" class="btn btn-info" style="margin-right: 0px;"><i
                        class="fas fa-file-download"></i> PDF</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import md5 from "md5";

export default ({
    computed: {
        ...mapState(['currentSong', 'data']),

        pdfUrl() {
            return `https://downloads.christiansongbook.in/export.php?id=${ md5(this.songId) }&type=pdf`;
        },

        pptUrl() {
            return `https://downloads.christiansongbook.in/index.php?id=${ md5(this.songId) }`;
        },

        songId() {
            return this.$route.params.id ? this.$route.params.id : '';
        },
    },
    watch: {
        songId() {
            if (this.data.songs && this.songId) {
                const song = this.data.songs.find(item => item.id === +this.songId);
                this.songTitle = song ? song.title : '';
            } else {
                this.songTitle = '';
            }
        }
    },
    data() {
        return {
            songTitle: ''
        }
    }
});
</script>

<style scoped>
.menu-topbar {
    border-bottom: 1px solid #ddd;
    background: #fafafa;
}

.overlay-back {
    display: inline-block;
    margin: 20px 10px 10px 20px;
}

.overlay-buttons a {
    color: #ffffff;
}

.format-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
