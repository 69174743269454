<template>
    <div class="ms_genres_box">
        <router-link :to="`/${ currentLanguage }/genres/${ id }`">
            <img :src="`${ imageCDN }cs/featured/${ image }.webp`" loading="lazy" alt class="img-fluid">
            <div class="ms_main_overlay">
                <div class="ms_box_overlay"></div>
                <div class="ms_play_icon"><img src="/images/svg/open.svg" alt></div>
                <div class="ovrly_text_div">
                    <span class="ovrly_text1"><span><span class="ovrly_text1"><a href="#" class="arrow-link">{{ title }}</a></span></span></span>
                    <span class="ovrly_text2"><span><span class="ovrly_text1"><a href="#" class="arrow-link">view songs</a></span></span></span>
                </div>
            </div>
            <div class="ms_box_overlay_on">
                <div class="ovrly_text_div">
                    <span class="ovrly_text1"><span><span class="ovrly_text1"><a href="#" class="arrow-link">{{ title }}</a></span></span></span>
                    <span class="ovrly_text2"><span><span class="ovrly_text1"><a href="#" class="arrow-link">{{ currentLanguage }}</a></span></span></span>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['id', 'title', 'image'],
    computed: mapState(['imageCDN', 'currentLanguage']),
});
</script>

