<template>
    <div>
        <div style="margin-top: 20px;">
            <table class="table table-borderless">
                <tbody>
                    <tr>
                        <th scope="row">Album</th>
                        <td><router-link :to="albumUrl">{{ album }}</router-link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Artist</th>
                        <td><router-link :to="artistUrl">{{ artist }}</router-link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Genre</th>
                        <td><router-link :to="genreUrl">{{ genre }}</router-link></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Scale</th>
                        <td>{{ scale }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Timing</th>
                        <td>{{ timing }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Beat</th>
                        <td>{{ beat }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"

export default ({
    data() {
        return {
            id: this.currentId
        }
    },
    watch: {
        '$route'() {
            this.id = this.$route.params.id;
        },
    },
    computed: {
        ...mapState(['currentSong', 'currentLanguage', 'currentId', 'data']),

        artist() {
            const song = this.getArtist();
            if (song) {
                return this.data.artists.find(item => item.id === song.artist).title;
            }
            return '-';
        },

        album() {
            const song = this.getAlbum();
            if (song) {
                return this.data.albums.find(item => item.id === song.album).title;
            }
            return '-';
        },

        genre() {
            const song = this.getGenre();
            if (song) {
                return this.data.genres.find(item => item.id === song.genre).title;
            }
            return '-';
        },

        albumUrl() {
            const song = this.getAlbum();
            if (song) {
                const id = this.data.albums.find(item => item.id === song.album).id;
                return `/${this.currentLanguage}/albums/${id}/1`;
            }
            return '/';
        },

        artistUrl() {
            const song = this.getArtist();
            if (song) {
                const id = this.data.artists.find(item => item.id === song.artist).id;
                return `/${this.currentLanguage}/artists/${id}/1`;
            }
            return '/';
        },

        genreUrl() {
            const song = this.getGenre();
            if (song) {
                const id = this.data.genres.find(item => item.id === song.genre).id;
                return `/${this.currentLanguage}/genres/${id}/1`;
            }
            return '/';
        },

        timing() {
            return this.currentSong.timing ? this.currentSong.timing : '-';
        },

        beat() {
            return this.currentSong.beat ? this.currentSong.beat : '-';
        },

        scale() {
            if (this.currentSong.chords && this.currentSong.chords.trim() !== '') {
                return this.currentSong.chords.trim().split(' ')[0];
            }
            return '-';
        },
    },
    methods: {
        getSong(key) {
            if (this.data.songs) {
                return this.data.songs.find(item => item[key] && item.id === +this.id);
            }
            return null;
        },

        getAlbum() { return this.getSong('album'); },

        getArtist() { return this.getSong('artist'); },

        getGenre() { return this.getSong('genre'); },
    }
})
</script>
