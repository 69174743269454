<template>
    <div class="container">
        <div class="preloader">
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<style scoped>
.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.preloader {
    width: 200px;
    height: 200px;
    display: inline-block;
    position: relative;
}

.preloader span {
    position: absolute;
    display: inline-block;
    width: 200px;
    height: 200px;
    background: #000;
    border-radius: 100%;

    -webkit-animation: preloader 1.5s linear infinite;
    -o-animation: preloader 1.5s linear infinite;
    animation: preloader 1.5s linear infinite;
}

.preloader span:nth-child(2) {
    animation-delay: -0.8s;
}

@keyframes preloader {
    from {
        transform: scale(0);
        opacity: 0.5;
    }

    to {
        transform: scale(1);
        opacity: 0;
    }
}
</style>