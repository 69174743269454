<template>
    <div class="ms_footer_wrapper">
        <div class="ms_footer_logo">
            <router-link to="/"><img :src="logo" :alt="appName" loading="lazy" class="img-fluid rounded-img"></router-link>
            <strong class="app_name">{{ appName }}</strong>
        </div>
        <div class="ms_footer_inner">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="footer_box">
                        <h1 class="footer_title">{{ appName }}</h1>
                        <p>{{ appDescription }}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer_box footer_app">
                        <h2 class="footer_title">Download our Apps</h2>
                        <p>Go Mobile with our app.<br> All your favourite songs at just one click. Download Now !</p>
                        <a :href="developer.android" target="_blank" class="foo_app_btn android-app"><img :src="developer.playStore" alt class="img-fluid"></a>
                        <a :href="developer.ios" target="_blank" class="foo_app_btn ios-app"><img :src="developer.appStore" alt class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer_box footer_subscribe">
                        <h2 class="footer_title">Hot Links</h2>
                        <ul style="margin: 0; padding: 0;">
                            <li style="padding: 5px 0;"><router-link to="/donate">Dontate &amp; Support Us</router-link></li>
                            <li style="padding: 5px 0;"><a href="https://wa.me/919940440443" target="_blank">Add / Request Songs <i class="fas fa-external-link-alt"></i></a></li>
                            <li style="padding: 5px 0;"><router-link to="/about-us">About Us</router-link></li>
                            <li style="padding: 5px 0;"><a href="https://docs.google.com/forms/d/e/1FAIpQLSek2GJH5wCjnJxE-ivEXfzH2z4N41NCbF6c9rp8UCyn3zM5Gg/viewform" target="_blank">Copyright Claims <i class="fas fa-external-link-alt"></i></a></li>
                            <li style="padding: 5px 0;"><a href="https://onlinebibles.org" target="_blank">Online Bibles <i class="fas fa-external-link-alt"></i></a></li>
                            <li style="padding: 5px 0;"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li style="padding: 5px 0;"><router-link to="/terms-of-service">Terms of Service</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer_box footer_contacts">
                        <h2 class="footer_title">contact us</h2>
                        <ul class="foo_con_info">
                            <li>
                                <div class="foo_con_icon">
                                    <img src="/images/svg/phone.svg" loading="lazy" alt>
                                </div>
                                <div class="foo_con_data">
                                    <span class="con-title">Call us :</span>
                                    <span>
                                        <a :href="contact.phoneLink">{{ contact.phone }}</a><br>
                                        <a :href="contact.mobileLink">{{ contact.mobile }}</a>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div class="foo_con_icon">
                                    <img src="/images/svg/message.svg" loading="lazy" alt>
                                </div>
                                <div class="foo_con_data">
                                    <span class="con-title">email us :</span>
                                    <span>
                                        <a :href="`mailto:${ contact.email1 }`">{{ contact.email1 }}</a><br>
                                        <a :href="`mailto:${ contact.email2 }`">{{ contact.email2 }}</a>
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <div class="foo_sharing">
                            <div class="share_title">follow us :</div>
                            <ul>
                                <li><a :href="`https://www.facebook.com/${ social.facebook }`" target="_blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                                <li><a :href="`https://twitter.com/${ social.twitter }`" target="_blank"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a :href="`https://www.youtube.com/c/${ social.youtube }?view_as=subscriber%3Fsub_confirmation%3D1`" target="_blank"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
                                <li><a :href="social.web" target="_blank"><i class="fa fa-globe" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----Copyright---->
        <div class="col-lg-12">
            <div class="ms_copyright">
                <div class="footer_border"></div>
                <p>Copyright &copy; 2009 - {{ (new Date()).getFullYear() }} | All Rights Reserved. Part of <a href="https://apamission.org" target="_blank">APA Mission</a> Ministry. Powered by <a href="https://verkkonet.com" target="_blank">VerkkoNet</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    computed: mapState(['appName', 'appDescription', 'logo', 'developer', 'contact', 'social']),
})
</script>

<style scoped>
.ms_footer_wrapper {margin-bottom: 0;}
.ms_footer_logo {display: flex; align-items: center; justify-content: center; flex-direction: column;}
.app_name{color: #ed516f;font-size: 1.1em;margin-top: 20px;}
</style>
