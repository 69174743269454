<template>
    <div class="ms_fea_album_slider">
        <div class="ms_heading">
            <h2 class="h4">Featured Albums</h2>
            <span class="veiw_all">
                <router-link :to="`/${currentLanguage}/albums`" class="album-link">view more</router-link>
            </span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default ({
    computed: mapState(['currentLanguage']),
})
</script>

