<template>
    <div class="col-sm-7 mobile-lyrics-view" @copy.prevent>
        <song-preloader v-show="fetchingSong"></song-preloader>
        <div class="canvas-container" @copy.prevent v-show="!fetchingSong">
            <lyric-options></lyric-options>
            <div v-show="currentSection === 'youtube' || currentSection === 'karoke'">
                <iframe width="560" height="315" frameborder="0" allowfullscreen style="margin-top: 10px;"
                    :src="`https://www.youtube.com/embed/${currentSong[currentSection]}`"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
            <div @copy.prevent v-html="songContent" class="lyric-view" :class="fontClass"
                v-show="currentSection === 'lyrics' || currentSection === 'romanised' || currentSection === 'chords'"
                :style="{ 'font-size': `${currentFont.fontSize}px`, 'line-height': `${currentFont.lineHeight}px` }">
            </div>
            <song-info v-show="currentSection === 'info' && currentSong.content.trim() !== ''"></song-info>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import LyricOptions from "./LyricOptions.vue";
import SongInfo from "./SongInfo.vue";
import SongPreloader from "../layouts/SongPreloader.vue";

export default ({
    components: {
        LyricOptions, SongInfo, SongPreloader
    },
    computed: {
        ...mapState(['currentSection', 'currentFont', 'currentSong', 'fetchingSong']),

        fontClass() { return this.currentSection != 'romanised' ? this.currentFont.font.toLowerCase().split(' ').join('-') : ''; },

        songContent() {
            let content = this.currentSong.content;
            if (this.currentSection === 'chords' && this.currentSong.chords) {
                const chords = this.currentSong.chords.split('\n');
                const song = this.currentSong.content.split('\n');
                let output = '';
                for (var i = 0; i < chords.length || i < song.length; i++) {
                    if (i < chords.length) { output += chords[i].replace(/ /g, '&nbsp;') + '\n'; }
                    if (i < song.length) { output += song[i] + '\n'; }
                }
                content = output;
            } else if (this.currentSection === 'romanised') {
                content = this.toRomanised(this.currentSong.content);
            }
            return content.split('\n\n').map(stanza => `<p>${stanza.split('\n')
                .join('<br>')}</p>`).join('');
        },
    },
})
</script>

<style scoped>
.lyric-view {
    margin: 10px 0px;
    overflow: auto;
    height: calc(100vh - 250px);
    text-transform: capitalize;
}
</style>
