<template>
    <page-template>
        <alphabet-picker></alphabet-picker>
        <all-languages title="Popular Languages" :languages="popularLanguages" />
        <featured-genres v-show="showFeaturedGenres"></featured-genres>
        <featured-artists v-show="showFeaturedArtists">
            <swiper-view :data="data.featuredArtists" type="artists" />
        </featured-artists>
        <featured-albums v-show="showFeaturedAlbums">
            <swiper-view :data="data.featuredAlbums" type="albums" />
        </featured-albums>
    </page-template>
</template>

<script>
import { mapState } from 'vuex';

import PageTemplate from '../layouts/PageTemplate.vue';
import AlphabetPicker from '../language/AlphabetPicker.vue';
import AllLanguages from '../language/AllLanguages.vue';
import FeaturedGenres from '../featured/FeaturedGenres.vue';
import FeaturedArtists from '../featured/FeaturedArtists.vue';
import FeaturedAlbums from '../featured/FeaturedAlbums.vue';
import SwiperView from '../layouts/SwiperView.vue';

export default ({
    title: ` Christian Songs | Christian Songbook | Part of APA Mission Ministry`,
    components: {
        PageTemplate, AlphabetPicker, AllLanguages, 
        FeaturedGenres, FeaturedArtists, 
        FeaturedAlbums, SwiperView
    },
    props: ['language', 'alphabet', 'page', 'id', 'listId', 'keyword'],
    provide() {
        return {
            id: this.id
        }
    },
    data() {
        return {
            pageTitle: '',
        }
    },
    computed: {
        ...mapState(['data', 'popularLanguages']),

        showFeaturedGenres() {
            return this.language === 'tamil' && !this.isVideoPage;
        },

        showFeaturedArtists() {
            return this.data.featuredArtists && !this.isVideoPage;
        },

        showFeaturedAlbums() {
            return this.data.featuredAlbums && !this.isVideoPage;
        },

        isVideoPage() { return this.$route.path.indexOf('video-songs') !== -1; }
    },
    watch: {
        alphabet() { this.handleAlphabetChange(); },
        language() { this.handleLanguageChange(); },
        page() {
            this.$store.state.currentPage = Number.isInteger(+this.page) ? +this.page : 1;
        },
        id() { this.handleIdChange(); },
        listId() { this.handleListIdChange(); },
        keyword() { this.handleKeywordChange(); }
    },
    methods: {
        
    },
    mounted() {
        if (!this.data.songs) {
            this.handleLanguageChange();
            this.handleAlphabetChange();
            this.handleListIdChange();
            this.handleKeywordChange();
        }
    }
})
</script>
