import { createStore } from "vuex";
import alphabets from './alphabets.js';
import fonts from './fonts.js';
import romanised from "./romanised.js";

const store = createStore({
    state() {
        return {
            auth: {},
            appName: 'Christian Songbook',
            alphabets: alphabets,
            fonts: fonts,
            scales: ['C', 'C#', 'Db', 'D', 'D#', 'Eb', 'E', 'F', 'F#', 'Gb', 'G', 'G#', 'Ab', 'A', 'Bb'],
            appDescription: 'Christian Songbook - Ultimate online songbook includes 38,000+ songs in 12 languages such as Tamil, English, Hindi, Telugu, Malayalam, Nepali, Kannada, Arabic, Chinese, Russian, Kamba and Swahili.',
            logo: 'https://ik.imagekit.io/pukqq5vmw/cs/images/cs_logo.webp',
            contact: {
                phone: '(+91) 4183-290055', phoneLink: 'tel:914183290055',
                mobile: '(+91) 9940-440-443', mobileLink: 'tel:919940440443',
                email1: 'tcs@apamission.org', email2: 'verkkonet@gmail.com'
            },
            developer: {
                android: 'https://play.google.com/store/apps/dev?id=7603393263138145587',
                playStore: 'https://ik.imagekit.io/pukqq5vmw/cs/images/google_play.webp',
                ios: 'https://apps.apple.com/in/developer/sam-solomon-prabu/id1221290833',
                appStore: 'https://ik.imagekit.io/pukqq5vmw/cs/images/app_store.webp',
            },
            social: {
                facebook: 'christian.union', twitter: 'verkkonet', youtube: 'SharoninRoja', web: 'https://apamission.org'
            },
            data: {},
            loading: true,
            fetchingSong: false,
            version: 3,
            dataCDN: 'https://samsolomonprabu.github.io/cdn/cs/',
            imageCDN: 'https://ik.imagekit.io/pukqq5vmw/',
            mediaCDN: 'https://img.youtube.com/vi/',
            // apiURL: 'http://localhost:3000/',
            apiURL: 'https://cs-backend-a5u5.onrender.com/',
            //apiURL: 'http://localhost/cs_web_new/php/',
            currentLanguage: null,
            currentAlphabet: null,
            currentPage: 1,
            currentId: null,
            currentListId: null,
            currentCategoryId: null,
            currentKeyword: null,
            currentSong: { title: 'Choose song to view the lyrics', content: '' },
            currentSection: 'lyrics',
            currentFont: { font: 'Default', fontSize: 16, lineHeight: 24 },
            paginationSize: 100,
            languages: [
                { id: 'arabic', label: 'Arabic (عربى)' },
                { id: 'chinese', label: 'Chinese (中文)' },
                { id: 'english', label: 'English' },
                { id: 'hindi', label: 'Hindi (हिंदी)' },
                { id: 'kamba', label: 'Kamba' },
                { id: 'kannada', label: 'Kannada (ಕನ್ನಡ)' },
                { id: 'malayalam', label: 'Malayalam (മലയാളം)' },
                { id: 'nepali', label: 'Nepali (नेपाली)' },
                { id: 'russian', label: 'Russian (русский)' },
                { id: 'swahili', label: 'Swahili (Kiswahili)' },
                { id: 'tamil', label: 'Tamil (தமிழ்)' },
                { id: 'telugu', label: 'Telugu (తెలుగు)' }
            ],
            popularLanguages: ['tamil', 'telugu', 'hindi', 'kannada', 'malayalam', 'english'],
            activeMenu: 'home',
            sidebarMenu: [
                { id: 'home', to: `/`, className: 'home-link', title: 'Home', icon: 'icon_discover', visible: true },
                { id: 'albums', to: `/albums`, className: 'album-link', title: 'Albums', icon: 'icon_albums', visible: false },
                { id: 'artists', to: `/artists`, className: 'artist-link', title: 'Artists', icon: 'icon_artists', visible: false },
                { id: 'genres', to: `/genres`, className: 'genre-link', title: 'Genres', icon: 'icon_genres', visible: false },
                { id: 'video-songs', to: `/video-songs`, className: '', title: 'Video Songs', icon: 'icon_tracks', visible: false },
                { id: 'karaoke-songs', to: `/karaoke-songs`, className: '', title: 'Karaoke Songs', icon: 'icon_music', visible: false },
                { id: 'praises', to: `/praises`, className: '', title: '1000 Praises', icon: 'icon_music', visible: false },
            ],
            isOverlayActive: false,
            modalOpen: false,
            romanised: romanised,
            groups: null,
            alertActive: false,
            alertMessage: '',
            pptBundles: {
                'tamil': 'ATY7UKG', 'english': 'WHLzmxv', 
                'telugu': 'xqbYEHe', 'hindi': 'rqs2q7z', 'kannada': 'CIQhPpl', 
                'malayalam': 'JaqPLTx', 'nepali': 'TeICHLM'
            },
            razorpayUrl: 'https://rzp.io/l/'
        }
    }
});

export default store;
