const fonts = {
    arabic: [],
    chinese: [],
    english: [
        'Default', 'Abel', 'Aclonica', 'DoHyeon', 'MavenPro', 'MontserratAlternates', 'Padauk',
        'Play', 'Raleway', 'SansitaSwashed', 'ZCOOLXiaoWei',
    ],
    hindi: [
        'Default', 'Arya', 'Biryani', 'Palanquin', 'Baloo 2', 'Khand', 'Rajdhani', 'Teko', 
        'Mukta', 'Noto Sans', 'Poppins',
    ],
    kamba: [],
    kannada: [
        'Default', 'Akaya Kanadaka', 'Baloo Tamma 2', 'Benne', 'Hubballi', 
        'Noto Sans Kannada', 'Noto Serif Kannada'
    ],
    malayalam: [
        'Default', 'Baloo Chettan 2', 'Chilanka', 'Gayathri', 'Manjari', 
        'Noto Sans Malayalam', 'Noto Serif Malayalam'
    ],
    nepali: [],
    russian: [],
    swahili: [],
    tamil: [
        'Default', 'Baloo Thambi 2', 'Catamaran', 'Hind Madurai', 'Arima Madurai', 'Mukta Malar', 
        'Pavanam', 'Baloo Thambi', 'Coiny', 'Meera Inimai', 'Kavivanar'
    ],
    telugu: [
        'Default', 'Hind Guntur', 'Ramabhadra', 'Baloo Tammudu 2', 'Mallanna', 'Suranna',
        'Gurajada', 'NTR', 'Mandali', 'Timmana', 'Chathura', 'Sree Krushnadevaraya',
        'Ramaraja', 'Lakki Reddy', 'Ravi Prakash', 'Peddana', 'Tenali Ramakrishna',
        'Gidugu', 'Suravaram', 'Dhurjati'
    ],
};

export default fonts;
