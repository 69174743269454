<template>
    <div class="ms_top_artist">
        <div class="ms_heading"><h2>{{ title }}</h2></div>
        <div class="container-fluid">
            <div class="row">
                <div v-for="category in data" :key="category.id" class="col-lg-2 col-md-6 col-6 category-item media-item arrow-parent">
                    <div class="ms_rcnt_box marger_bottom30">
                        <router-link :to="`/${ language }/${ type }/${ category.id }/1`" style="display: inline-block;"
                                    class="data-category-name data-media-name arrow-link ellipsis" :alt="category.title"
                                    :title="category.title" @click="showOverlay">
                            <div class="ms_rcnt_box_img">
                                <img :src="`${ imageCdn }${ type }/${ language }/${ category.id }.webp?v=3`" :alt="category.title"
                                    class="img-fluid data-category-img data-media-img" loading="lazy" :title="category.title">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon"><img src="/images/svg/open.svg"></div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3>{{ category.title }}</h3>
                                <p class="data-subcategory-name ellipsis" :alt="category.eng_title" :title="category.eng_title">{{ category.eng_title }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    props: ['title', 'type', 'data'],
    computed: {
        imageCdn() { return this.$store.state.imageCDN; },
        language() { return this.$store.state.currentLanguage; }
    },
})
</script>

