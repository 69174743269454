<template>
    <div class="modal centered-modal show" role="dialog" @click="hideModal"
        aria-modal="true" :style="`display: ${ modalOpen ? 'block;' : 'none;' }`">
        <div class="modal-dialog register_dialog" @click.prevent="ignoreClick" style="max-width: 430px;">
            <div class="modal-content" style="padding: 0;">
                <button type="button" class="close dialog-close-btn" @click="hideModal">
                    <i class="fas fa-times" aria-hidden="true"></i>
                </button>
                <div class="modal-body">
                    <div class="ms_register_form">
                        <h2>{{ title }}</h2>
                        <component :is="component" :data="data" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import AddGroup from "../group/AddGroup.vue";
import DeleteGroup from "../group/DeleteGroup.vue";
import AddSong from "../group/AddSong.vue";

export default ({
    props: ['title', 'component', 'data'],
    computed: {
        ...mapState(['modalOpen'])
    },
    components: {
        AddGroup, DeleteGroup, AddSong
    },
    methods: {
        ignoreClick(event) {
            event.stopPropagation();
        }
    }
});
</script>

<style scoped>
.dialog-close-btn {
    position: absolute;
    right: 10px; 
    top: 10px; 
    font-size: 1em; 
    color: #000000; 
    cursor: pointer;
    z-index: 999;
}
.ms_register_form {
    width: 100%;
    padding: 25px 50px;
}
</style>
