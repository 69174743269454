<template>
    <div class="col-sm-3 cont">
        <div class="ms_heading list-cont">
            <h2 :class="fontClass" style="color: #000000;">{{ title }}</h2>
            <div class="custom-songlist-opts" v-show="isList">
                <a href="javascript:;" @click="copyUrl" class="edit-btn" title="Share Songlist"
                    style="margin-right: 10px;">
                    <span class="fas fa-share" aria-hidden="true"></span>
                </a>
                <a href="javascript:;" @click="editGroup" class="edit-btn" title="Edit Songlist"
                    style="margin-right: 10px;">
                    <span class="fas fa-edit" aria-hidden="true"></span>
                </a>
                <a href="javascript:;" @click="deleteGroup" class="delete-btn" title="Delete Songlist">
                    <span class="far fa-trash-alt" aria-hidden="true"></span>
                </a>
            </div>
        </div>
        <div class="songlist-cont" :class="fontClass">
            <router-link v-for="(song, index) in songsForAlphabet" :key="song.id" :to="songUrl(song.id)"
                class="songlist-item" :title="song.title" :class="this.currentId == song.id ? 'active' : ''"
                @click="fetchSong(song.id)">{{ ((currentPage - 1) * paginationSize) + (index + 1) }}. {{ song.title }}
                <i v-if="!isList" class="fa fa-plus" title="Add to Song list" 
                    @click.prevent="addSong(song.id)"></i>
                <i v-if="isList" class="far fa-trash-alt" title="Delete from Song list"
                    @click.prevent="removeSong(song.id)"></i>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['filteredSongs', 'category'],
    computed: {
        ...mapState([
            'currentLanguage', 'currentPage', 'paginationSize', 'currentCategoryId',
            'currentAlphabet', 'currentId', 'data', 'groups', 'currentFont'
        ]),

        fontClass() { return this.currentFont.font.toLowerCase().split(' ').join('-'); },

        songsForAlphabet() {
            if (this.filteredSongs.length > 0) {
                const start = (this.currentPage - 1) * this.paginationSize;
                return this.filteredSongs.slice(start, start + this.paginationSize);
            }
            return [];
        },

        title() {
            if (this.category) {
                if (this.category === 'search') {
                    return `For '${this.$route.params.keyword}' (${this.songsCount} songs)`;
                } else {
                    const info = this.data[this.category];
                    if (info) {
                        const item = info.find(item => item.id == this.currentCategoryId);
                        if (item) {
                            return info ? item.title + ` (${this.songsCount} songs)` : '';
                        }
                    }
                }
                return '';
            } else if (this.isList) {
                const listId = this.$route.params.listId;
                if (this.groups) {
                    const group = this.groups.find(item => item.id === listId);
                    return group ? group.name : '';
                }
                return '';
            } else {
                return `For '${this.currentAlphabet}': (${this.songsCount} songs)`;
            }
        },

        songsCount() {
            if (this.data.songs) {
                return this.filteredSongs.length;
            }
            return 0;
        },

        isVideoPage() { return this.$route.path.indexOf('video-songs') !== -1; },

        isList() { return this.$route.path.indexOf('list') !== -1; }
    },
    methods: {
        editGroup() {
            this.showModal('Edit Group', 'add-group', { id: this.$route.params.listId });
        },

        deleteGroup() {
            const group = this.groups.find(group => group.id === this.$route.params.listId);
            group && this.showModal(`Delete Songlist '${group.name}'`, 'delete-group', group);
        },

        addSong(songId) {
            this.showModal('Choose your song list', 'add-song', { id: songId });
        },

        removeSong(songId) {
            const group = this.groups.find(group => group.id === this.$route.params.listId);
            if (group) {
                const index = group.songs.findIndex(item => item == songId);
                group.songs.splice(index, 1);
                localStorage.setItem('groups', JSON.stringify(this.groups));

                this.$store.state.alertActive = true;
                this.$store.state.alertMessage = `Song removed from '${ group.name }'`;
                setTimeout(() => {
                    this.$store.state.alertActive = false;
                }, 2000);
            }
        },

        songUrl(songId) {
            if (this.category) {
                if (this.category === 'search') {
                    const keyword = this.$route.params.keyword;
                    return `/${this.currentLanguage}/${this.category}/${keyword}/${this.currentPage}/song/${songId}`;
                }
                return `/${this.currentLanguage}/${this.category}/${this.currentCategoryId}/${this.currentPage}/song/${songId}`;
            } else if (this.isList) {
                const listId = this.$route.params.listId;
                const s = this.$route.query.s;
                const t = this.$route.query.t;
                return `/${this.currentLanguage}/list/${listId}/${this.currentPage}/song/${songId}?s=${s}&t=${t}`;
            } else {
                return this.isVideoPage ?
                    `/${this.currentLanguage}/video-songs/${this.currentAlphabet}/${this.currentPage}/video/${songId}` :
                    `/${this.currentLanguage}/${this.currentAlphabet}/${this.currentPage}/song/${songId}`;
            }
        },
    },
    mounted() {
        const songId = this.$route.params.id;
        if (songId) {
            this.fetchSong(songId);
        }
    }
})
</script>


<style scoped>
.cont {
    border-right: 1px solid #ddd;
}

h1 {
    padding-left: 5px;
}

.list-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    height: 50px;
}

.songlist-cont {
    height: calc(100vh - 245px);
    overflow: auto;
}

.song-title-cont {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
}

.songlist-cont a {
    padding: 12px !important;
    border-radius: 10px;
}

.songlist-cont a:hover {
    background: rgb(237 81 111 / 30%);
}

.songlist-cont a.active {
    background: #ed516f;
    color: #ffffff;
}

.songlist-cont a i {
    background: none;
    transition: all 0.3s ease-in-out 0s;
    display: block;
    opacity: 0;
}

.songlist-cont a:hover i {
    opacity: 1;
}
</style>
