<template>
    <div class="ms_radio_wrapper">
        <div class="ms_heading">
            <h2>Popular Downloads</h2>
        </div>
        <swiper slides-per-view="4" space-between="30" navigation :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="item in data" :key="item.id" class="featured-category arrow-parent">
                <div class="ms_rcnt_box">
                    <div class="ms_rcnt_box_img">
                        <img :src="`${ imageCDN }${ item.img }`" loading="lazy" alt>
                        <div class="ms_main_overlay">
                            <div class="ms_box_overlay"></div>
                            <div class="ms_play_icon">
                                <a :href="item.link" target="_blank"><img src="/images/svg/open.svg" alt></a>
                            </div>
                        </div>
                    </div>
                    <div class="ms_rcnt_box_text">
                        <h3><a :href="item.link" target="_blank">{{ item.title }}</a></h3>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { mapState } from 'vuex';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    components: {
        Swiper, SwiperSlide
    },
    data() {
        return {
            data: [
                { img: 'cs/downloads/wallpaper.webp', link: 'https://apamission.org/christian-wallpapers.php', title: 'Christian Wallpapers' },
                { img: 'cs/downloads/screensaver.webp', link: 'https://apamission.org/screensavers.php', title: 'Screensavers' },
                { img: 'cs/downloads/songs-ppt.webp', link: 'https://apamission.org/ppt-songs.php', title: 'Songs PPT Bundles' },
                { img: 'cs/downloads/rhythm-pad.webp', link: 'https://apamission.org/rhythm-pad-tones.php', title: 'Rhythm Pad Tones' },
                { img: 'cs/downloads/keyboard.webp', link: 'https://apamission.org/keyboard-tones.php', title: 'Keyboard Tones' }
            ]
        }
    },
    computed: mapState(['imageCDN']),
    setup() {
        const onSwiper = () => {
        };
        const onSlideChange = () => {
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
};
</script>
