<template>
    <div class="ms_loader" v-show="$store.state.loading">
        <div class="wrap loader-container">
            <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/loader.webp" alt>
            <span class="loader-title">{{ title }}</span>
            <span class="loader-subtitle">{{ subtitle }}</span>
        </div>
    </div>
</template>

<script>

export default ({
    props: {
        title: {
            type: String, 
            default: 'We are doing awesome work for you. Please be patient...',
        },
        subtitle: {
            type: String, 
            default: 'Hallelujah! Sing unto him a new song.',
        }
    }
});
</script>

<style scoped>

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader-title {
    color: #bbbbbb;
}

.loader-subtitle {
    color: #ffed00;
    font-size: 2em;
    padding-top: 8em;
    text-align: center;
    line-height: 1.2em;
}

</style>