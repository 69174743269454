<template>
    <preloader-view />
    <div :class="`ms_main_wrapper ${ className }`">
        <sidebar-view />
        <topbar-view />
        <div class="ms_content_wrapper padder_top60">
            <slot></slot>
            <downloads-view></downloads-view>
        </div>
        <footer-view />
    </div>
    <overlay-view  @copy.prevent />
    <div v-if="modalOpen" class="modal-backdrop show" @click="hideModal"></div>
    <modal-dialog :title="modalTitle" :component="modalComponent" :data="data"></modal-dialog>
    <alert-view v-show="alertActive"></alert-view>
</template>

<script>
import PreloaderView from './PreloaderView.vue';
import SidebarView from './SidebarView.vue';
import TopbarView from './TopbarView.vue';
import OverlayView from '../overlay/OverlayView.vue';
import DownloadsView from './DownloadsView.vue';
import FooterView from './FooterView.vue';
import ModalDialog from './ModalDialog.vue';
import AlertView from './AlertView.vue';
import { mapState } from 'vuex';

export default ({
    props: ['extra'],
    components: {
        PreloaderView, SidebarView, TopbarView, AlertView,
        OverlayView, DownloadsView, ModalDialog, FooterView
    },
    computed: {
        ...mapState(['modalOpen', 'groups', 'alertActive']),

        className() {
            return this.extra ? `${ this.extra } padder_top60` : '';
        }
    },
    provide() {
        return {
            showModal: this.showModal,
            hideModal: this.hideModal,
            modalOpen: this.modalOpen,
            copyUrl: this.copyUrl
        }
    },
    data() {
        return {
            modalTitle: '',
            modalComponent: '',
            data: null,
        }
    },
    methods: {
        showModal(title, component, data = null) {
            this.$store.state.modalOpen = true;
            this.modalTitle = title;
            this.modalComponent = component;
            this.data = data;
        },

        hideModal() {
            this.$store.state.modalOpen = false;
        },

        async copyUrl() {
            try {
                const url = window.location.href;
                await navigator.clipboard.writeText(url);
                this.$store.state.alertActive = true;
                this.$store.state.alertMessage = 'URL copied to clipboard!';
                setTimeout(() => {
                    this.$store.state.alertActive = false;
                }, 2000);
            } catch (error) { console.log(error); }
        }
    },
    mounted() {
        if (!localStorage.getItem('groups')) {
            const group = [{ id: this.randomString(5), name: 'Favorites', songs: [] }];
            localStorage.setItem('groups', JSON.stringify(group));
        }
        let groups = JSON.parse(localStorage.getItem('groups'));
        if (this.$route.params.listId) {
            const group = groups.find(group => group.id === this.$route.params.listId);
            if (!group) {
                groups.push({
                    id: this.$route.params.listId,
                    name: this.$route.query.t,
                    songs: this.$route.query.s.split('-').map(item => +item)
                })
            }
        }
        localStorage.setItem('groups', JSON.stringify(groups));
        this.$store.state.groups = groups;
    }
});
</script>
