<template>
    <page-template extra="privacy-policy">
        <div class="ms_profile_box ms_heading" style="width: 100%;">
            <h2 style="color: #ffffff; margin-bottom: 20px;">Support Us</h2>
            <p style="color: #dddddd;">We’ve been providing useful, adaptable, affordable, ministry-focused
                softwares</p>
            <p style="color: #dddddd; padding-bottom: 30px;">for pastors, church leaders, church &amp; belivers
                since 2009.</p>
            <div>
                <a href="https://rzp.io/l/kqm758s" target="_blank" class="ms_btn reg_btn"
                    style="width: auto; padding-left: 15px; padding-right: 15px;"><span>Donate via
                        RazorPay</span></a>
                <a href="https://www.paypal.me/apamission/100" target="_blank" class="ms_btn reg_btn"
                    style="width: auto; padding-left: 15px; padding-right: 15px;"><span>Donate via PayPal</span></a>
            </div>
        </div>
        <div class="caption wrapper-lg ms_heading" style="margin-top: 30px;">
            <h2 class="post-title">Donate &amp; Support Us</h2><br>
            <div class="post-sum">
                <div class="row">
                    <div class="col-lg-6">
                        <h4>Our Needs</h4>
                        <ul>
                            <li>Musical instruments &amp; PA Systems: Rs.1,50,000/-</li>
                            <li>Registered office = Rs.3,10,100/- per annum</li>
                            <li>Home for children = Rs.11,74,600/- per annum</li>
                            <li>Home for aged = Rs. 11,74,600/- per annum</li>
                            <li>Free tuition center = Rs.3,77,000/- per annum</li>
                            <li>Free medical camps for poor people = Rs.1,74,000/- per annum</li>
                            <li>Free Tailoring Classes = Rs.2,32,000/- per annum</li>
                            <li>Free music classes for poor = Rs.5,67,000/- per annum</li>
                            <li>Awareness Camps ( AIDS &amp; HUMAN ) = Rs.32,500/- per annum</li>
                            <li>Vocational Training Centres = Rs.4,76,000/- per annum</li>
                            <li>to construct 5 village branch churches Rs.93,75,000/- per annum</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/qr-code.jpg">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <h4>Our Bank Details</h4>
                        <p style="padding-bottom: 40px;">Those who wish to pay to ministry can send them to this
                            bank account FCRA, 12AA income tax</p>
                    </div>
                    <div class="col-lg-4">
                        <p><strong>Church Ministry</strong></p>
                        <p>Account : Apostolic Pentecostal Assembly</p>
                        <p>Account Number : 988264197</p>
                        <p>Bank Name : Indian Bank</p>
                        <p>IFSC : IDIB000K083</p>
                        <p>Branch : Kilkodungalore</p>
                    </div>
                    <div class="col-lg-4">
                        <p><strong>FCRA Account</strong></p>
                        <p>Account Name : Apostolic Pentecostal Assembly</p>
                        <p>Account Number : 589440598</p>
                        <p>Bank Name : Indian Bank</p>
                        <p>IFSC : IDIB000K083</p>
                        <p>Branch : Kilkodungalore</p>
                    </div>
                    <div class="col-lg-4">
                        <p><strong>Sharonin Roja Magazine</strong></p>
                        <p>Account Name : Sharonin Roja</p>
                        <p>Bank Name : Corporation Bank</p>
                        <p>Account Number : 303201601000021</p>
                        <p>IFSC : CORP0003032</p>
                        <p>Branch : Mamandur</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <h4>Our Activities</h4>
                        <ul>
                            <li><strong>Village Ministry</strong> - To proclaim the love of Jesus Christ through
                                Public meetings, healing Ministries and by such other expressions of love and
                                concern to the poor and needy, regardless of caste, creed, colour or community.</li>
                            <li><strong>Sponsor a child</strong> - Sponsor a child and transform lives of the
                                children. You can do this by contributing Rs 12,000 yearly for a child.</li>
                            <li>End child labour - To prevent, protect and rehabilitate children below the age of 14
                                year from chid labour</li>
                            <li><strong>Training Programs</strong> - To organise and set up institutes like
                                Discipleship centres, prayer groups, bible schools, and leadership training
                                programmes to promote the objects of the trust.</li>
                            <li><strong>Media Ministry</strong> - To conduct Radio programmes and Television
                                programmes and arrange for recording of messages, religious songs and to make them
                                available for the public without profit and it make available Audio &amp; Video
                                Cassettes, Cds, etc.,</li>
                            <li><strong>Social Ministry</strong> - To conduct poor feeding and to five food,
                                clothing cash grants to the poor and to afford relief to people in distress and
                                affected by earthquake, flood, famine, pestilence and other causes and to grant
                                donations for the support of the inmates of the orphanages, Rescue houses and
                                similar institutions and societies.</li>
                            <li><strong>Medical Assistance</strong> - To provide cash and other forms of relieve to
                                the orphans without adequate means of sustenance, the unemployed without adequate
                                means of sustenance, the poor for education of their children their medical needs
                                etc., and on the occasion of deaths, etc., in their families and those who have
                                suffered in accidents.</li>
                        </ul>
                    </div>
                </div>
                <h4>Our Contact Details</h4>
                <p><strong>Apostolic Pentecostal Assembly Trust,</strong> No.150, APA Prayer House, Zion Nagar,
                    Kilkodungalore, Vandavasi TK, Tiruvanamalai DT, Pin: 604403</p>
                <p>Phone: <a href="tel:914183290055">(+91) 4183-290055</a>, Mobile: <a href="tel:919940440443">(+91)
                        9940-440-443</a></p>
            </div>
        </div>
    </page-template>
</template>

<script>
import PageTemplate from '../layouts/PageTemplate.vue';

export default ({
    components: {
        PageTemplate
    },
})
</script>
