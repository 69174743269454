<template>
    <div id="topbar" class="ms_header">
        <search-view></search-view>
        <div class="ms_top_right">
            <div class="ms_top_btn guest-user" style="display: none;">
                <a id="btnLogin" href="javascript:;" class="ms_btn login_btn" data-toggle="modal" data-target="#myModal1"><span>login</span></a>
            </div>
            <div class="ms_top_btn registered-user" style="display: none;">
                <a href="javascript:;" class="ms_admin_name">
                    <span class="data-user-name"></span>
                    <img src="" class="rounded-img data-user-img">
                    <!-- <span class="ms_pro_name">SS</span> -->
                </a>
                <ul class="pro_dropdown_menu" style="top: 80px !important;">
                    <li><a href="profile.php">Profile</a></li>
                    <li><a href="subscription.php">Subscription</a></li>
                    <li><a href="javascript:;" onclick="logout();">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="alert alert-warning alert-dismissible fade show" style="position: fixed; top: 100px; left: 50%; transform: translateX(-50%); z-index: 99999; padding: 5px 10px; border-radius: 5px; display: none;" role="alert">
        <span id="alertMsg"></span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchView from './SearchView.vue';

export default ({
    components: {
        SearchView
    },
    computed: mapState(['currentLanguage'])
})
</script>
