<template>
    <div @click.prevent>
        <p>The song '{{ songTitle }}' will be added to the selected songlist.</p>
        <ul class="lang_list">
            <li v-for="group in groups" :key="group.id" 
                @click="addSong(data.id, group.id)">{{ group.name }}</li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['data'],
    computed: mapState(['groups']),
    methods: {
        addSong(songId, groupId) {
            const group = this.groups.find(item => item.id === groupId);
            if (group) {
                const set = new Set(group.songs);
                set.add(songId);
                group.songs = [...set];
                localStorage.setItem('groups', JSON.stringify(this.groups));
                this.hideModal();
                this.$store.state.alertActive = true;
                this.$store.state.alertMessage = `Song added to '${ group.name }'`;

                setTimeout(() => {
                    this.$store.state.alertActive = false;
                }, 2000);
            }
        }
    }
})
</script>

<style scoped>
p {
    padding: 0px 10px;
    font-style: normal;
    color: #ffffff;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 20px;
}

li {
    color: #fff;
    cursor: pointer;
    width: 50%;
    float: left;
    padding: 10px 20px;
    border: 1px solid rgba(255, 255, 255, .25);
    border-width: 1px 1px 1px 0;
    margin-bottom: -1px;
    margin-right: -1px;
    background: #f76c87;
}
</style>
