<template>
    <div class="bg-light dk">
        <div class="error-page">
            <div>
                <h1 class="h text-white" style="margin: 0;">404</h1>
                <h2 style="text-align: center;">Page not found !!</h2>
            </div>
            <div class="list-group auto m-b-sm m-b-lg" style="width: 33%;">
                <router-link to="/" class="list-group-item">
                    <i class="fa fa-chevron-right icon-muted"></i>
                    <i class="fa fa-fw fa-home icon-muted"></i> Goto homepage
                </router-link>
                <a href="mailto:tcs@apamission.org" class="list-group-item">
                    <i class="fa fa-chevron-right icon-muted"></i>
                    <i class="fa fa-fw fa-question icon-muted"></i> Send us a tip
                </a>
                <a href="tel:919597713368" class="list-group-item">
                    <i class="fa fa-chevron-right icon-muted"></i>
                    <i class="fa fa-fw fa-phone icon-muted"></i> Call us
                    <span style="padding-left: 20px;"></span>
                    <span class="phone-no">+91-95977-13368</span>
                </a>
            </div>
        </div>
        <footer>
            <p>Copyright © 2009 - 2020 | All Rights Reserved. Part of <a href="https://apamission.org"
                    target="_blank">APA Mission</a> Ministry. Powered by <a href="https://verkkonet.com"
                    target="_blank">VerkkoNet</a>.</p>
        </footer>
    </div>
</template>


<style scoped>
body {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #788188;
    background-color: transparent;
    -webkit-font-smoothing: antialiased;
    line-height: 1.40;
}

.bg-light {
    background-color: #e0eaec;
}

.h.text-white {
    font-size: 170px;
    color: #ffffff;
    text-shadow: 0 1px 0 #d9d9d9, 0 2px 0 #d0d0d0, 0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2);
}

.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

a.list-group-item {
    color: #555;
}

.list-group-item {
    border-color: #eaeef1;
    padding-right: 15px;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
}

a {
    color: #545a5f;
    text-decoration: none;
}

a.list-group-item:hover,
a.list-group-item:focus {
    background-color: #eef3f4;
}

a.list-group-item:hover,
a.list-group-item:focus {
    text-decoration: none;
    background-color: #f5f5f5;
}

.list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

a:hover,
a:focus {
    color: #303437;
    text-decoration: none;
}

a.list-group-item {
    color: #555;
}

a:hover,
a:focus {
    color: #2a6496;
    text-decoration: underline;
}

a:active,
a:hover {
    outline: 0;
}

h1 {
    margin: .67em 0;
}

.list-group-item i:first-child {
    float: right;
}

.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.phone-no {
    background-color: #62bcce;
    color: #eaf6f9;
    border-radius: 10px;
    font-size: 12px;
    padding: 3px 7px;
}

footer {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
}
#app, .bg-light {
    height: 100vh;
}
h2 {
    color: rgb(120, 129, 136);
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
</style>