<template>
    <div class="overlay-container" :class="activeClass" @copy.prevent onscroll="return false;">
        <overlay-titlebar></overlay-titlebar>
        <div class="lyrics-holder" @copy.prevent>
            <div class="ms_releases_wrapper" @copy.prevent style="margin-top: 15px;">
                <div class="row" @copy.prevent>
                    <song-list :filteredSongs="filteredSongs" :category="category"></song-list>
                    <lyrics-view></lyrics-view>
                    <song-toolbar></song-toolbar>
                </div>
            </div>
        </div>
        <pagination-bar :filteredSongs="filteredSongs"></pagination-bar>
    </div>
</template>

<script>
import Fuse from 'fuse.js';
import { mapState } from "vuex";
import OverlayTitlebar from "./OverlayTitlebar.vue";
import PaginationBar from './PaginationBar.vue';
import SongList from "../song/SongList.vue";
import LyricsView from "../song/LyricsView.vue";
import SongToolbar from "../song/SongToolbar.vue";

export default ({
    components: {
        OverlayTitlebar, SongList,
        LyricsView, SongToolbar, PaginationBar
    },
    computed: {
        ...mapState([
            'currentId', 'currentLanguage', 'currentAlphabet', 'currentPage', 'currentSong',
            'paginationSize', 'isOverlayActive', 'data', 'apiURL', 'currentCategoryId',
            'fonts', 'currentSection', 'scales', 'currentFont', 'groups'
        ]),
        activeClass() {
            return this.isOverlayActive ? 'toggle' : '';
        },
        category() {
            const path = this.$route.path;
            if (path.indexOf('albums') != -1) {
                return 'albums';
            } else if (path.indexOf('artists') != -1) {
                return 'artists';
            } else if (path.indexOf('genres') != -1) {
                return 'genres';
            } else if (path.indexOf('search') != -1) {
                return 'search'
            }
            return null;
        },
        filteredSongs() {
            let songs = [];
            if (this.data.songs) {
                if (this.category) {
                    if (this.category === 'search') {
                        const keyword = this.$route.params.keyword;
                        const fuse = new Fuse(this.data.songs, {
                            keys: ['title', 'eng_title'], minMatchCharLength: 3, threshold: 0.4,
                        });
                        songs = fuse.search(keyword).map(song => song.item);
                    } else {
                        const category = this.category;
                        const singular = category.substr(0, category.length - 1);
                        songs = this.data.songs.filter(song => {
                            return song[singular] === +this.currentCategoryId;
                        });
                    }
                } else if (this.isList) {
                    const listId = this.$route.params.listId;
                    if (this.groups) {
                        const group = this.groups.find(item => item.id === listId);
                        if (group) {
                            songs = group.songs.map(songId => {
                                return this.data.songs.find(item => item.id === songId);
                            });
                        }
                    }
                } else {
                    if (this.isVideoPage) {
                        songs = this.data.songs.filter(song => {
                            return song.youtube && song.youtube.trim() !== '' && song.title.startsWith(this.currentAlphabet);
                        });
                    } else {
                        songs = this.data.songs.filter(song => {
                            return song.title.startsWith(this.currentAlphabet);
                        });
                    }
                }
            }
            return this.category === 'search' ? songs : songs.sort((a, b) => {
                return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
            });
        },

        fontClass() { return this.currentFont.font.toLowerCase().split(' ').join('-'); },

        isVideoPage() { return this.$route.path.indexOf('video-songs') !== -1; },

        isList() { return this.$route.path.indexOf('list') !== -1; },
    },
    watch: {
        currentAlphabet() { this.showOverlay(); },
    },
    mounted() {
        if (this.$route.params.page) {
            this.$store.state.currentPage = this.$route.params.page;
        }
    }
})
</script>

<style scoped>
.lyrics-holder {
    width: calc(100% - 20px);
    height: calc(100vh - 180px);
    margin-left: 20px;
    overflow: hidden;
}

.ms_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.ms_heading h1 {
    color: #000000;
}

.canvas-container {
    height: calc(100vh - 245px);
    overflow: auto;
    position: relative;
}

.song-title-cont {
    margin-bottom: 10px;
}
</style>
