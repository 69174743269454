<template>
    <div class="ms_rcnt_box">
        <router-link :to="`${ itemUrl }${ item.id }/1`" @click="showOverlay"
            class="data-category-name arrow-link" :alt="item.title" :title="item.title">
            <div class="ms_rcnt_box_img">
                <img class="data-category-img" loading="lazy"
                    :src="`${ imageUrl }/${ item.id }.webp?v=${ version }`" 
                    :alt="item.title" :title="item.title">
                <div class="ms_main_overlay">
                    <div class="ms_box_overlay"></div>
                    <div class="ms_play_icon"><img src="/images/svg/open.svg" alt=""></div>
                </div>
            </div>
            <div class="ms_rcnt_box_text">
                <h3>{{ item.title }}</h3>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['id', 'type'],
    computed: {
        ...mapState(['imageCDN', 'version', 'currentLanguage', 'data']),
        imageUrl() {
            return `${ this.imageCDN }${ this.type }/${ this.currentLanguage }/`;
        },
        itemUrl() {
            return `/${ this.currentLanguage }/${ this.type }/`;
        },
        item() {
            return this.data[this.type].find(item => item.id === this.id);
        }
    },
})
</script>

