<template>
    <page-template extra="privacy-policy">
        <div class="ms_heading">
            <h2 class="post-title">About Us</h2>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="ms_profile_box ms_heading" style="width: 100%; height: 100%;">
                    <img src="https://samsolomonprabu.github.io/cdn/cs/team/sam1.jpg" style="border-radius: 50%; width: 80%;">
                    <h2 class="centered-underline">Sam Solomon Prabu SD</h2>
                    <p>CEO, VerkkoNet</p>
                    <p style="text-align: justify; padding: 10px 40px; color: #cccccc;">Beloved brothers and sister
                        in Christ,</p>
                    <p style="text-align: justify; padding: 10px 40px; color: #cccccc;">Greetings to you in the name
                        of our Lord and Saviour Jesus Christ. It gives me immense pleasure to reach you all through
                        this app. I'm always interested in doing something to glorify the name of our Lord. I
                        started developing apps in windows platform, slowly moved to Android and to iOS. By the
                        grace of God, all those apps are useful to many people across the world. I'm always thankful
                        to my parents for all their supports in all the phases of my life.</p>
                    <p style="text-align: justify; padding: 10px 40px; color: #cccccc;">You can reach us for
                        technical needs of your churches / ministries. Please support us through your prayers. May
                        God bless you from his abundance.</p>
                    <div class="contact-opts" style="margin-top: 20px;">
                        <a href="mailto:sam@apamission.org"><i class="far fa-envelope"></i></a>
                        <a href="https://wa.me/919597713368" target="_blank"><i class="fab fa-whatsapp"></i></a>
                        <a href="https://twitter.com/samsolomonprabu" target="_blank"><i class="fab fa-twitter"></i></a>
                        <a href="https://instagram.com/samsolomonprabu" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                        <a href="https://youtube.com/verkkonet" target="_blank"><i class="fab fa-youtube"></i></a>
                        <a href="https://samsolomonprabu.com" target="_blank"><i class="fas fa-globe"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ms_profile_box ms_heading" style="width: 100%; height: 100%;">
                    <img src="https://samsolomonprabu.github.io/cdn/cs/team/samson.jpg" style="border-radius: 50%; width: 80%;">
                    <h2 class="centered-underline">Samson Socrates SD</h2>
                    <p>Android &amp; Content Head, VerkkoNet</p>
                    <p style="text-align: justify; padding: 10px 40px; color: #cccccc;">தேவனுடைய பரிசுத்த
                        நாமத்திற்கு மகிமை உண்டாவதாக !!.</p>
                    <p style="text-align: justify; padding: 10px 40px; color: #cccccc;">
                        இந்த செயலி வாயிலாக உங்களை சந்திப்பதில் மிகுந்த மகிழ்ச்சி அடைகிறேன். நமது செயலி
                        உருவாக்கப்பட்ட நாள் முதல் இதனோடு ஒரு அங்கமாய் இருந்திருப்பதை எண்ணி தேவனை துதிக்கிறேன்.
                        <br><br>
                        நமது செயலியின் துவக்க காலங்களில் பாடல்களை தட்டச்சு செய்து, அதை திருத்தம் செய்வதுடன் இதனை
                        சார்ந்த கூடுதல் வேலைகளுக்கும் மற்றவர்களையே நம்பி இருந்ததாலும், இதற்கான செலவீனங்களின் தாக்கம்
                        எங்களை பாதித்ததாலும் வெகுகாலம் 1500 பாடல்களுக்கு மேல் இணைத்து மேம்படுத்த இயலாத நிலையில்
                        தள்ளப்பட்டோம். பின்னர், பல சிரமங்களிடையே இதனை நாங்களே பொறுப்பேற்று செய்ய தொடங்கி, தேவனுடைய
                        இணையற்ற கிருபையால் இன்று 14000 பாடல்களக்கு மேல் இணைத்துள்ளோம்.
                        <br><br>
                        முன்பின் அறிமுகம் இல்லாத நிலையிலும் உங்களில் பலர் எங்களை அன்போடு தொடர்பு கொண்டு
                        விசாரிப்பதும் தட்டச்சு செய்வதில் தங்களது பங்களிப்புகளை செலுத்தியும் வருகின்றீர்கள். நமது
                        தமிழ் மக்கள் பலர் பயன்பட்டதின் விளைவாக பிற மொழி மக்களுக்கும் அவரவர் மொழிகளில் இந்த செயலி
                        கிடைக்க ஆவன செய்து வருகிறோம்.
                        <br><br>
                        எங்களோடு கரம் கோர்த்து இந்த ஊழியத்தை தாங்கும்படி உங்களை வரவேற்கிறோம்..! வாழ்த்துகிறோம்..!!
                        கர்த்தர் தாமே உங்களை ஆசீர்வதிப்பாராக.. ஆமென் !.
                    </p>
                    <div class="contact-opts" style="margin-top: 20px;">
                        <a href="mailto:samson@apamission.org"><i class="far fa-envelope"></i></a>
                        <a href="https://wa.me/919940440443" target="_blank"><i class="fab fa-whatsapp"></i></a>
                        <a href="tel:919940440443"><i class="fas fa-phone-alt"></i></a>
                        <a href="https://twitter.com/SamsonSocrates" target="_blank"><i class="fab fa-twitter"></i></a>
                        <a href="https://instagram.com/samsonsocrates" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                        <a href="https://youtube.com/sharoninroja" target="_blank"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="caption wrapper-lg ms_heading" style="margin-top: 30px;">
            <h2 class="post-title" style="margin-top: 30px; margin-bottom: 20px;">App Stats</h2><br>
            <div class="row">
                <div class="col-lg-2 col-sm-6 col-6 arrow-parent">
                    <div class="ms_genres_box" style="background: #1b2039;">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/stats_bg.jpg" alt="" class="img-fluid"
                            style="opacity: 0;">
                        <div
                            style="position: absolute;top: 45%;left: 50%;transform: translateX(-50%) translateY(-50%);color: #eeeeee;font-size: 70px;">
                            12</div>
                        <div style="position: absolute;bottom: 20px;width: 100%;text-align: center;color: #dddddd;">
                            Languages</div>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-6 arrow-parent">
                    <div class="ms_genres_box" style="background: #1b2039;">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/stats_bg.jpg" alt="" class="img-fluid"
                            style="opacity: 0;">
                        <div
                            style="position: absolute;top: 45%;left: 50%;transform: translateX(-50%) translateY(-50%);color: #eeeeee;font-size: 35px;">
                            35,300+</div>
                        <div style="position: absolute;bottom: 20px;width: 100%;text-align: center;color: #dddddd;">
                            Songs</div>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-6 arrow-parent">
                    <div class="ms_genres_box" style="background: #1b2039;">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/stats_bg.jpg" alt="" class="img-fluid"
                            style="opacity: 0;">
                        <div
                            style="position: absolute;top: 45%;left: 50%;transform: translateX(-50%) translateY(-50%);color: #eeeeee;font-size: 55px;">
                            395</div>
                        <div style="position: absolute;bottom: 20px;width: 100%;text-align: center;color: #dddddd;">
                            Artists</div>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-6 arrow-parent">
                    <div class="ms_genres_box" style="background: #1b2039;">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/stats_bg.jpg" alt="" class="img-fluid"
                            style="opacity: 0;">
                        <div
                            style="position: absolute;top: 45%;left: 50%;transform: translateX(-50%) translateY(-50%);color: #eeeeee;font-size: 55px;">
                            145</div>
                        <div style="position: absolute;bottom: 20px;width: 100%;text-align: center;color: #dddddd;">
                            Albums</div>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-6 arrow-parent">
                    <div class="ms_genres_box" style="background: #1b2039;">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/stats_bg.jpg" alt="" class="img-fluid"
                            style="opacity: 0;">
                        <div
                            style="position: absolute;top: 45%;left: 50%;transform: translateX(-50%) translateY(-50%);color: #eeeeee;font-size: 55px;">
                            61</div>
                        <div style="position: absolute;bottom: 20px;width: 100%;text-align: center;color: #dddddd;">
                            Genres</div>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-6 arrow-parent">
                    <div class="ms_genres_box" style="background: #1b2039;">
                        <img src="https://ik.imagekit.io/pukqq5vmw/cs/images/stats_bg.jpg" alt="" class="img-fluid"
                            style="opacity: 0;">
                        <div
                            style="position: absolute;top: 45%;left: 50%;transform: translateX(-50%) translateY(-50%);color: #eeeeee;font-size: 35px;">
                            6,000+</div>
                        <div style="position: absolute;bottom: 20px;width: 100%;text-align: center;color: #dddddd;">
                            Video Songs</div>
                    </div>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import { mapState } from 'vuex';
import PageTemplate from '../layouts/PageTemplate.vue';

export default ({
    components: {
        PageTemplate
    },
    computed: mapState(['dataCDN']),
    data() {
        return {
            effectiveFrom: '10-Mar-2019'
        }
    }
})
</script>
