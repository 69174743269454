<template>
	<router-view></router-view>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: 'App',
	metaInfo: {
		meta: [
			{ vmid: 'description', name: 'description', content: 'sf' },
		]
	},
	computed: {
		...mapState([
			'currentLanguage', 'isOverlayActive', 'apiURL', 'alphabets', 'data',
			'currentFont', 'currentAlphabet', 'romanised', 'auth'
		]),
		fontName() { return this.currentFont.font.split(' ').join('+'); },
		isVideoPage() { return this.$route.path.indexOf('video-songs') !== -1; }
	},
	provide() {
		return {
			song: this.song
		}
	},
	watch: {
		isOverlayActive() {
			document.body.style.overflow = this.isOverlayActive ? 'hidden' : '';
		}
	},
	methods: {

		
	},
	created() {
		this.login();
	},
	mounted() {
		this.$store.state.loading = false;
		if (localStorage.getItem('currentFont')) {
			this.$store.state.currentFont = JSON.parse(localStorage.getItem('currentFont'));
			this.handleFontChange();
		}
	}
}
</script>

<style>
.ms_heading {
    margin-bottom: 10px;
}
.fa, .far, .fas {
	font-display: swap;
}

.rounded-img {
	border-radius: 50%;
	width: 100px;
	border: 2px solid #fff;
	box-shadow: 0 0 12px 1px #999;
	width: 55px;
}
.open_menu .ms_logo_open {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.firebaseui-tos {
	color: #000000;
}
ul.pro_dropdown_menu:after {
	right: 40px !important;
}
.overlay-container {
	top: 80px !important;
}
.ms_nav_close {
	top: 82px !important;
}
.baloo-thambi-2 { font-family: 'Baloo Thambi 2', cursive; }
.catamaran { font-family: 'Catamaran', sans-serif; }
.hind-madurai { font-family: 'Hind Madurai', sans-serif; }
.arima-madurai { font-family: 'Arima Madurai', cursive; }
.mukta-malar { font-family: 'Mukta Malar', sans-serif; }
.pavanam { font-family: 'Pavanam', sans-serif; }
.baloo-thambi { font-family: 'Baloo Thambi', cursive; }
.coiny { font-family: 'Coiny', cursive; }
.meera-inimai { font-family: 'Meera Inimai', sans-serif; }
.kavivanar { font-family: 'Kavivanar', cursive; }
.hind-guntur { font-family: 'Hind Guntur', sans-serif; }
.ramabhadra { font-family: 'Ramabhadra', sans-serif; }
.baloo-tammudu-2 { font-family: 'Baloo Tammudu 2', cursive; }
.mallanna { font-family: 'Mallanna', sans-serif; }
.suranna { font-family: 'Suranna', serif; }
.gurajada { font-family: 'Gurajada', serif; }
.ntr { font-family: 'NTR', sans-serif; }
.mandali { font-family: 'Mandali', sans-serif; }
.timmana { font-family: 'Timmana', sans-serif; }
.chathura { font-family: 'Chathura', sans-serif; }
.sree-krushnadevaraya { font-family: 'Sree Krushnadevaraya', serif; }
.ramaraja { font-family: 'Ramaraja', serif; }
.lakki-reddy { font-family: 'Lakki Reddy', cursive; }
.ravi-prakash { font-family: 'Ravi Prakash', cursive; }
.peddana { font-family: 'Peddana', serif; }
.tenali-ramakrishna { font-family: 'Tenali Ramakrishna', sans-serif; }
.gidugu { font-family: 'Gidugu', sans-serif; }
.suravaram { font-family: 'Suravaram', serif; }
.dhurjati { font-family: 'Dhurjati', sans-serif; }
.arya { font-family: 'Arya', sans-serif; }
.biryani { font-family: 'Biryani', sans-serif; }
.palanquin { font-family: 'Palanquin', sans-serif; }
.baloo-2 { font-family: 'Baloo 2', sans-serif; }
.khand { font-family: 'Khand', sans-serif; }
.rajdhani { font-family: 'Rajdhani', sans-serif; }
.teko { font-family: 'Teko', sans-serif; }
.mukta { font-family: 'Mukta', sans-serif; }
.noto-sans { font-family: 'Noto Sans', sans-serif; }
.poppins { font-family: 'Poppins', sans-serif; }
.baloo-chettan-2 { font-family: 'Baloo Chettan 2', sans-serif; }
.chilanka { font-family: 'Chilanka', sans-serif; }
.gayathri { font-family: 'Gayathri', sans-serif; }
.manjari { font-family: 'Manjari', sans-serif; }
.noto-sans-malayalam { font-family: 'Noto Sans Malayalam', sans-serif; }
.noto-serif-malayalam { font-family: 'Noto Serif Malayalam', serif; }
.abel { font-family: 'Abel', sans-serif; }
.aclonica { font-family: 'Aclonica', sans-serif; }
.dohyeon { font-family: 'DoHyeon', sans-serif; }
.mavenpro { font-family: 'MavenPro', sans-serif; }
.montserratalternates { font-family: 'MontserratAlternates', sans-serif; }
.padauk { font-family: 'Padauk', sans-serif; }
.play { font-family: 'Play', sans-serif; }
.raleway { font-family: 'Raleway', sans-serif; }
.sansitaswashed { font-family: 'SansitaSwashed', sans-serif; }
.zcoolxiaowei { font-family: 'ZCOOLXiaoWei', sans-serif; }
.akaya-kanadaka { font-family: 'Akaya Kanadaka', cursive; }
.baloo-tamma-2 { font-family: 'Baloo Tamma 2', cursive; }
.benne { font-family: 'Benne', serif; }
.hubballi { font-family: 'Hubballi', cursive; }
.noto-sans-kannada { font-family: 'Noto Sans Kannada', sans-serif; }
.noto-serif-kannada { font-family: 'Noto Serif Kannada', serif; }
</style>
