<template>
    <div class="ms_genres_wrapper ms_genres_single">
        <div class="row">
            <div class="col-lg-12">
                <div class="ms_heading">
                    <h2>{{ title }}</h2>
                </div>
            </div>
            <language-item v-for="language in languages" :key="language" :language="language"></language-item>
        </div>
    </div>
</template>

<script>
import LanguageItem from './LanguageItem.vue';

export default ({
    props: ['title', 'languages'],
    components: {
        LanguageItem
    }
})
</script>

