<template>
    <div class="ms_featured_slider">
        <div class="ms_heading">
            <h2>{{ title }}</h2>
            <span class="veiw_all"><router-link :to="`/${ currentLanguage }/${ type }`">view more</router-link></span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default ({
    props: ['title', 'type'],
    computed: mapState(['currentLanguage']),
})
</script>
