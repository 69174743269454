<template>
    <div @click.prevent>
        <div class="form-group">
            <input type="text" ref="groupName" v-model="groupName" placeholder="Song list name"
                @keypress.enter="addOrUpdate" class="form-control kanni-enabled-processed" />
        </div>
        <a href="#" class="ms_btn" @click="addOrUpdate">{{ buttonText }}</a>
        <a href="#" class="ms_btn" style="margin-left: 10px;" @click="hideModal">Close</a>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    props: ['data'],
    computed: mapState(['groups', 'modalOpen']),
    watch: {
        modalOpen() {
            this.modalOpen && this.focusText();
        }
    },
    data() {
        return {
            groupName: '',
            buttonText: 'Add'
        }
    },
    methods: {
        addGroup() {
            this.$store.state.groups.push({
                id: this.randomString(5),
                name: this.groupName,
                songs: [],
            });
        },
        addOrUpdate() {
            if (this.data) {
                const group = this.groups.find(item => item.id === this.data.id);
                group.name = this.groupName;
                localStorage.setItem('groups', JSON.stringify(this.groups));
            } else {
                this.$store.state.groups.push({
                    id: this.randomString(5),
                    name: this.groupName,
                    songs: [],
                });
            }
            localStorage.setItem('groups', JSON.stringify(this.groups));
            this.hideModal();
            this.groupName = '';
        },
        focusText() {
            setTimeout(() => {
                this.$refs.groupName.focus();
            }, 300);
        }
    },
    mounted() {
        if (this.data) {
            const group = this.groups.find(item => item.id === this.data.id);
            this.groupName = group.name;
            this.buttonText = 'Update';
        }
        this.focusText();
    }
});
</script>

