<template>
    <div class="col-sm-2 song-toolbar privacy-policy">
        <div class="ms_heading">
            <h2>Customisation</h2>
        </div>
        <form @submit.prevent>
            <div class="form-group" v-show="langFonts.length > 0" style="margin-right: 20px;">
                <label for="fontSelect"><strong>Font: </strong></label>
                <select class="form-control" @change="handleFontChange" id="fontSelect" v-model="currentFont.font">
                    <option v-for="font in langFonts" :key="font">{{ font }}</option>
                </select>
            </div>
            <div class="form-group" style="margin-right: 20px;">
                <label for="fontSize"><strong>Font Size: </strong></label>
                <input id="fontSize" class="form-control" @change="handleFontChange"
                    v-model="currentFont.fontSize" type="number">
            </div>
            <div class="form-group" style="margin-right: 20px;">
                <label for="lineHeight"><strong>Line Height: </strong></label>
                <input id="lineHeight" class="form-control" @change="handleFontChange"
                    v-model="currentFont.lineHeight" type="number">
            </div>
            <div class="form-group" v-show="currentSection === 'chords'" style="margin-right: 20px;">
                <label><strong>Tranpose Scale </strong></label>
                <select class="form-control" :value="currentScale" @change="transposeScale">
                    <option v-for="note in scales" :key="note">{{ note }}</option>
                </select>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { transpose } from 'chord-transposer';

export default ({
    computed: {
        ...mapState([
            'currentLanguage', 'currentSection', 'scales', 'currentSection',
            'currentFont', 'fonts', 'currentSong'
        ]),

        langFonts() {
            return this.currentLanguage ? this.fonts[this.currentLanguage] : [];
        },

        currentScale() {
            if (this.currentSong.chords && this.currentSong.chords.trim() !== '') {
                return this.currentSong.chords.trim().split(' ')[0];
            }
            return '';
        },
    },
    methods: {
        transposeScale(event) {
            const toKey = event.currentTarget.value;
            this.$store.state.currentSong.chords = transpose(this.currentSong.chords).fromKey(this.currentScale).toKey(toKey).toString();
        },
    }
})
</script>

<style scoped>
.song-toolbar {
    border-left: 1px solid #ccc;
}
.format-options {
     display: flex; 
     align-items: center;
}
.form-control {
    border: 1px solid rgb(206, 212, 218);
}
</style>
