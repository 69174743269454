<template>
    <div class="ms_top_left">
        <h5><router-link to="/">Christian Songbook</router-link></h5>
        <div class="ms_top_search" v-show="currentLanguage" :class="{ focused: focused }">
            <input id="txtSearch" @blur="onBlur" @focus="onFocus" type="text" 
                class="form-control kanni-enabled" v-model="keyword"
                @keypress.enter="search"
                :placeholder="placeholder" autocomplete="off" />
            <span class="search_icon" @click="search">
                <img src="/images/svg/search.svg" alt>
            </span>
        </div>
        <a :href="bundleUrl" style="text-transform:capitalize;" v-html="bundleTitle"></a>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    computed: {
        ...mapState(['currentLanguage', 'data', 'pptBundles', 'razorpayUrl']),

        placeholder() {
            return this.currentLanguage ? `Search all ${ this.currentLanguage } songs` : 'Search';
        },

        bundleTitle() {
            if (this.pptBundles[this.currentLanguage]) {
                return `${ this.currentLanguage } Songs PPT Bundle`;
            }
            return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        },
        
        bundleUrl() {
            if (this.pptBundles[this.currentLanguage]) {
                return `${this.razorpayUrl}${this.pptBundles[this.currentLanguage]}`;
            }
            return '#';
        }
    },
    data() {
        return {
            focused: false,
            keyword: ''
        }
    },
    methods: {
        onBlur() { this.focused = this.keyword !== ''; },
        onFocus() { this.focused = true; },
        search() { 
            this.$router.push(`/${ this.currentLanguage }/search/${ this.keyword }/1`); 
            this.showOverlay();
        }
    },
    mounted() {
        if (this.$route.params.keyword) {
            this.keyword = this.$route.params.keyword;
            this.focused = true;
            this.showOverlay();
        }
    }
})
</script>

<style scoped>
.ms_top_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ms_top_search {
    transition: all 0.3s ease-in-out 0s;
}

.focused {
    max-width: 45% !important;
}
</style>
