<template>
    <swiper slides-per-view="4" space-between="30" navigation :pagination="{ clickable: true }"
         @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="id in data" :key="id" class="featured-category arrow-parent">
            <category-list :id="id" :type="type"></category-list>
        </swiper-slide>
    </swiper>
    <div @click="swiper.slidePrev()" class="swiper-button-next4 slider_nav_next downloads-next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false"></div>
    <div @click="swiper.slideNext()" class="swiper-button-prev4 slider_nav_prev downloads-prev swiper-button-disabled" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="true"></div>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import CategoryList from './CategoryList.vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    props: ['data', 'type'],
    components: {
        Swiper, SwiperSlide, CategoryList
    },
    setup() {
        const swiper = useSwiper();
        const onSwiper = () => {};
        const onSlideChange = () => {};
        return {
            swiper,
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
};
</script>

<style>
.swiper-button-prev, .swiper-button-next {
    display: none !important;
}
</style>
