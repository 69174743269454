<template>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <span>{{ alertMessage }}</span>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    computed: mapState(['alertMessage'])
})
</script>


<style scoped>
.alert {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
