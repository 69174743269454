import { createRouter, createWebHistory } from 'vue-router';

import WelcomePage from '../components/pages/WelcomePage.vue';
import HomePage from '../components/pages/HomePage.vue';
import CategoryPage from '../components/pages/CategoryPage.vue';
import KaraokePage from '../components/pages/KaraokePage.vue';
import AboutPage from '../components/pages/AboutPage.vue';
import PrivacyPolicy from '../components/pages/PrivacyPolicy.vue';
import TermsOfService from '../components/pages/TermsOfService.vue';
import DonatePage from '../components/pages/DonatePage.vue';
import ErrorPage from '../components/pages/ErrorPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/:language/albums', component: CategoryPage, props: true },
        { path: '/:language/albums/:cid/:page', component: CategoryPage, props: true },
        { path: '/:language/albums/:cid/:page/song/:id', component: CategoryPage, props: true },
        { path: '/:language/artists', component: CategoryPage, props: true },
        { path: '/:language/artists/:cid/:page', component: CategoryPage, props: true },
        { path: '/:language/artists/:cid/:page/song/:id', component: CategoryPage, props: true },
        { path: '/:language/genres', component: CategoryPage, props: true },
        { path: '/:language/genres/:cid/:page', component: CategoryPage, props: true },
        { path: '/:language/genres/:cid/:page/song/:id', component: CategoryPage, props: true },
        { path: '/:language/video-songs', component: HomePage, props: true },
        { path: '/:language/video-songs/:alphabet', component: HomePage, props: true },
        { path: '/:language/video-songs/:alphabet/:page', component: HomePage, props: true },
        { path: '/:language/video-songs/:alphabet/:page/video/:id', component: HomePage, props: true },
        { path: '/:language/karaoke-songs', component: KaraokePage, props: true },
        { path: '/:language/karaoke-songs/:id', component: KaraokePage, props: true },
        { path: '/:language', component: HomePage, props: true },
        { path: '/:language/:alphabet', component: HomePage, props: true },
        { path: '/:language/:alphabet/:page', component: HomePage, props: true },
        { path: '/:language/:alphabet/:page/song/:id', component: HomePage, props: true },
        { path: '/:language/list/:listId/:page', component: HomePage, props: true },
        { path: '/:language/list/:listId/:page/song/:id', component: HomePage, props: true },
        { path: '/:language/search/:keyword/:page', component: HomePage, props: true },
        { path: '/:language/search/:keyword/:page/song/:id', component: HomePage, props: true },
        { path: '/about-us', component: AboutPage },
        { path: '/privacy-policy', component: PrivacyPolicy },
        { path: '/terms-of-service', component: TermsOfService },
        { path: '/donate', component: DonatePage },
        { path: '/', component: WelcomePage },
        { path: '/:notFound(.*)', component: ErrorPage }
    ],
    scrollBehavior() {
        return { top: 0 }
    },
});

export default router;