<template>
    <page-template>
        <karaoke-list></karaoke-list>
    </page-template>
</template>

<script>
import { mapState } from 'vuex';

import PageTemplate from '../layouts/PageTemplate.vue';
import KaraokeList from '../karaoke/KaraokeList.vue';

export default ({
    title: 'Karaoke Songs | Christian Songbook | Part of APA Mission Ministry',
    components: {
        PageTemplate, KaraokeList
    },
    props: ['language', 'alphabet', 'page', 'id'],
    provide() {
        return {
            id: this.id
        }
    },
    computed: {
        ...mapState(['data', 'popularLanguages']),
    },
    watch: {
        language() { this.handleLanguageChange(); },
        id() { this.handleIdChange(); },
    },
    methods: {
        
    },
    mounted() {
        if (!this.data.songs) {
            this.handleLanguageChange();
        }
        this.initStoreData();
    }
})
</script>

<style scoped>
body {
    overflow: hidden;
}
.ms_content_wrapper {
    margin-left: 120px;
    margin-right: 40px;
}
</style>
