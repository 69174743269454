import axios from "axios";

export default {
    created() {

    },
    methods: {
        showOverlay: function() {
			if (this.$route.params.alphabet || this.$route.params.cid || 
				this.$route.params.listId || this.$route.params.keyword) {
				this.$store.state.isOverlayActive = true; 
			} else {
				this.$store.state.isOverlayActive = false; 
			}
		},

        hideOverlay: function() {
			this.$store.state.isOverlayActive = false; 
			this.$store.state.currentSong = { title: 'Choose song to view the lyrics', content: '' };
		},

        fetchSongsList: function() {
			this.$store.state.loading = true;
			axios.get(`${ this.$store.state.dataCDN }v3/${ this.$store.state.currentLanguage }.json`)
                .then(response => {
                    this.$store.state.data = response.data;
					this.$store.state.loading = false;
					this.romaniseTitles();
                })
                .catch(error => {
					this.$store.state.loading = false;
					console.log(error);
				});
        },

        romaniseTitles: function() {
			if (this.data.songs) {
				this.data.songs.map(item => {
					return item.eng_title = this.toRomanised(item.title);
				});
			}
		},

		fetchSong: function(songId) {
            this.$store.state.fetchingSong = true;
			axios.post(`${ this.$store.state.apiURL }fetch`, { song: this.getRandomisedSongId(songId) }, {
				headers: { 'Authorization': `Bearer ${ this.$store.state.auth.accessToken }` }
			}).then(response => {
                this.$store.state.currentSong = response.data;
                this.$store.state.fetchingSong = false;
				this.$store.state.currentSection = this.isVideoPage ? 'youtube' : 'lyrics';
            }).catch(error => {
                this.$store.state.fetchingSong = false;
				if (error.response.status === 403) {
					this.login(this.fetchSong);
				}
            });
        },

		getRandomisedSongId: function(songId) {
            const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
            const arr = songId.toString().split('').reverse();

            let randomisedId = '';
            arr.forEach(char => {
                const cipher = this.randomString(random(6, 6));
                const prefix = this.randomString(random(3, 3));
                const suffix = this.randomString(random(3, 3));
                randomisedId += `${ cipher }-${ prefix }${ char }${ suffix }-`;
            });
            randomisedId += `${ this.randomString(random(6, 6)) }`;
            return randomisedId;
        },

        randomString: function(n) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const length = characters.length;
            for (let i = 0; i < n; i++) {
                result += characters.charAt(Math.floor(Math.random() * length));
            }
            return result;
        },

		handleFontChange: function() {
			let link = document.getElementById('gfont');
			if (this.currentFont.font === 'Default') {
				link && link.remove();
				return;
            }
			if (!link) {
				link = document.createElement('link');
			}
			link.id = 'gfont';
			link.rel = 'stylesheet';
			link.href = `https://fonts.googleapis.com/css?family=${ this.fontName }&display=swap`;
			document.head.appendChild(link);

			// Store in a localstorage
			localStorage.setItem('currentFont', JSON.stringify(this.currentFont));
		},

		toRomanised: function(text) {
			const langLetters = this.$store.state.romanised[`${ this.$store.state.currentLanguage }Letters`];
			if (langLetters) {
				const romLetters = this.$store.state.romanised[`${ this.$store.state.currentLanguage }Romanised`];
				langLetters.forEach((letter, index) => {
					text = text.replace(new RegExp(letter, 'g'), romLetters[index]);
				});
				return text;
			}
			return text;
		},

		login: function(callback) {
			axios.post(`${this.$store.state.apiURL}login`, {
				client: this.randomString(64)
			}).then(response => {
				this.$store.state.auth = response.data;
				callback && callback(this.$route.params.id);
			}).catch(() => { console.log('Login error') });
		},

        handleKeywordChange: function() {
            this.$store.state.currentKeyword = this.keyword;
            this.keyword && this.showOverlay();
        },

        handleIdChange: function() {
            this.$store.state.currentId = this.id;
        },

        handleListIdChange: function() {
            this.$store.state.currentListId = this.listId;
            this.listId && this.showOverlay();
        },

        handleLanguageChange: function() {
            this.$store.state.currentLanguage = this.language;
            if (!this.language) { this.hideOverlay(); }
            this.fetchSongsList();
        },

        handleAlphabetChange: function() {
            if (this.alphabet) {
                this.showOverlay();
            }
            this.$store.state.currentAlphabet = this.alphabet;
        },

        initStoreData: function() {
            this.$store.state.currentPage = this.page;
            this.$store.state.currentAlphabet = this.alphabet;
            this.$store.state.currentLanguage = this.language;
            this.$store.state.currentId = this.id;
            this.$store.state.currentListId = this.listId;
            this.$store.state.currentKeyword = this.keyword;
        },

        handleSongChange: function() {
            this.$store.state.currentId = this.id;
        },

        handlePageChange: function() {
            this.$store.state.currentPage = this.page;
        },

        handleCategoryChange: function() {
            if (this.cid) {
                console.log(this.cid);
                this.$store.state.currentCategoryId = this.cid;
                this.showOverlay();
                //this.cid ? this.showOverlay() : this.hideOverlay();
            }
        },

        handleCategoryData: function() {
            let path = this.$route.path;
            if (path.indexOf('genres') != -1) {
                this.type = 'genres';
                this.title = 'All Genres';
                this.categoryData = this.data.genres;
                this.featuredTitle = 'Featured Genres';
                this.featuredData = this.language === 'tamil' ? this.featuredGenres : null;
            } else if (path.indexOf('albums') != -1) {
                this.type = 'albums';
                this.title = 'All Albums';
                this.categoryData = this.data.albums;
                this.featuredTitle = 'Featured Albums';
                this.featuredData = this.data.featuredAlbums;
            } else if (path.indexOf('artists') != -1) {
                this.type = 'artists';
                this.title = 'All Artists';
                this.categoryData = this.data.artists;
                this.featuredTitle = 'Featured Artists';
                this.featuredData = this.data.featuredArtists;
            }
        }
    }
};
