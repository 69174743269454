<template>
    <div class="ms_genres_wrapper">
        <div class="row">
            <div class="col-lg-12">
                <div class="ms_heading">
                    <h2>Top Genres</h2>
                    <span class="veiw_all">
                        <a class="genre-link" :href="`/${ currentLanguage }/genres`">view more</a>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 arrow-parent">
                <genre-item id="20" title="ஆராதனை பாடல்கள்" image="worship"></genre-item>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 arrow-parent">
                        <genre-item id="4" title="திருவிருந்து பாடல்கள்" image="communion"></genre-item>
                    </div>
                    <div class="col-lg-8 col-sm-6 arrow-parent">
                        <genre-item id="2" title="புதுவருட பாடல்கள்" image="new-year"></genre-item>
                    </div>
                    <div class="col-lg-8 col-sm-6 arrow-parent">
                        <genre-item id="3" title="திருமண பாடல்கள்" image="wedding"></genre-item>
                    </div>
                    <div class="col-lg-4 col-sm-6 arrow-parent">
                        <genre-item id="23" title="சுவிசேஷ பாடல்கள்" image="gospel"></genre-item>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6 arrow-parent">
                <genre-item id="8" title="சிறுவர் பாடல்கள்" image="children"></genre-item>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GenreItem from "./GenreItem.vue";

export default ({
    components: { GenreItem },
    computed: mapState(['currentLanguage']),
});
</script>
