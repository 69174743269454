<template>
    <div @click.prevent>
        <div class="ms_register_form">
            <p>This will delete the songlist and the songs associated to it. Do you want to continue?</p>
            <a href="#" class="ms_btn" @click="deleteGroup">Confirm Delete</a>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"

export default ({
    props: ['data'],
    computed: mapState(['groups', 'currentLanguage']),
    methods: {
        deleteGroup() {
            if (this.data) {
                const index = this.groups.findIndex(group => group.id === this.data.id);
                this.groups.splice(index, 1);
                localStorage.setItem('groups', JSON.stringify(this.groups));
                this.hideModal();
                this.hideOverlay();
                this.$router.push(`/${this.currentLanguage}`);
            }
        }
    }
})
</script>


<style scoped>
.ms_register_form {
    width: 100%;
    padding: 0;
}

p {
    text-transform: none;
    color: #fff;
    font-size: 1em;
    margin: 0 0 15px 0;
    font-weight: normal;
    font-style: normal;
}
</style>
