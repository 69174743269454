<template>
    <div :class="this.visible ? 'ms_sidemenu_wrapper open_menu' : 'ms_sidemenu_wrapper'">
        <div class="ms_nav_close" @click="toggle"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
        <div class="ms_sidemenu_inner">
            <div class="ms_logo_inner">
                <div class="ms_logo">
                    <router-link class="home-link" to="/"><img :src="logo" alt class="img-fluid rounded-img">
                    </router-link>
                </div>
                <div class="ms_logo_open">
                    <router-link class="home-link" to="/"><img :src="logo" alt class="img-fluid rounded-img">
                    </router-link>
                    <strong style="color: #ed516f;font-size: 1.1em;">{{ appName }}</strong>
                </div>
            </div>
            <div class="ms_nav_wrapper" style="bottom: 5px; overflow: auto; overflow-x: hidden;">
                <ul>
                    <li v-for="item in menu" :key="item.id" v-show="item.visible">
                        <router-link :to="`/${currentLanguage}${item.to}`" :title="item.title"
                            @click="setActiveMenu(item.id)"
                            :class="[item.className, item.id === activeMenu ? 'active' : '']">
                            <span class="nav_icon"><span class="icon" :class="item.icon"></span></span>
                            <span class="nav_text">{{ item.id }}</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="nav_playlist">
                    <li>
                        <a href="javascript:;" title="Create Playlist" @click="showModal('Add Group', 'add-group');">
                            <span class="nav_icon"><span class="icon icon_c_playlist"></span></span>
                            <span class="nav_text">create songlist</span>
                        </a>
                    </li>
                    <li v-for="group in groups" :key="group.id" class="data">
                        <router-link :to="toUrl(group)" @click="showOverlay" class="data-link" :title="group.name">
                            <span class="nav_icon"><span class="icon icon_fe_playlist"></span></span>
                            <span class="nav_text custom-list-name">{{ group.name }} ({{ group.songs.length }})</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="nav_downloads">
                    <li>
                        <a id="subscription" href="subscription.php" title="Subscription" style="display: none;">
                            <span class="nav_icon"><span class="icon icon_purchased"></span></span>
                            <span class="nav_text">Subscription</span>
                        </a>
                    </li>
                    <li>
                        <router-link to="/donate" title="Donate">
                            <span class="nav_icon"><span class="icon icon_favourite"></span></span>
                            <span class="nav_text">Donate</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default ({
    computed: {
        ...mapState([
            'currentLanguage', 'appName', 'logo', 'sidebarMenu',
            'activeMenu', 'data', 'groups'
        ]),

        menu() {
            this.getMenuItem(this.sidebarMenu, 'albums').visible = this.data.albums && this.data.albums.length > 0;
            this.getMenuItem(this.sidebarMenu, 'artists').visible = this.data.artists && this.data.artists.length > 0;
            this.getMenuItem(this.sidebarMenu, 'genres').visible = this.data.genres && this.data.genres.length > 0;
            this.getMenuItem(this.sidebarMenu, 'video-songs').visible = this.data.songs && this.data.songs.filter(song => song.youtube && song.youtube !== '').length > 0;
            this.getMenuItem(this.sidebarMenu, 'karaoke-songs').visible = this.data.songs && this.data.songs.filter(song => song.karoke && song.karoke !== '').length > 0;
            return (this.sidebarMenu);
        }
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        getMenuItem(menu, id) {
            return menu.find(item => item.id === id);
        },

        setActiveMenu(menu) {
            this.$store.state.activeMenu = menu || 'home';
            this.hideOverlay();
        },

        toggle() { this.visible = !this.visible; },

        toUrl(group) {
            return `/${this.currentLanguage}/list/${group.id}/1?s=${group.songs.join('-')}&t=${group.name}`;
        }
    },
    mounted() {
        this.setActiveMenu(this.$route.path.split('/')[2]);
    }
})
</script>
