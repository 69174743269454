<template>
    <ul class="nav nav-tabs" v-show="currentSong.content.trim() !== ''">
        <li class="nav-item">
            <a href="#" class="nav-link" @click="setSection('lyrics')"
                :class="{ active: currentSection === 'lyrics' }">Lyrics</a>
        </li>
        <li class="nav-item" v-show="isRomanisedAvailable">
            <a href="#" class="nav-link" @click="setSection('romanised')"
                :class="{ active: currentSection === 'romanised' }">Romanised</a>
        </li>
        <li class="nav-item" v-show="currentSong.chords && currentSong.chords.trim() !== ''">
            <a href="#" class="nav-link" @click="setSection('chords')"
                :class="{ active: currentSection === 'chords' }">Chords</a>
        </li>
        <li class="nav-item" v-show="currentSong.youtube && currentSong.youtube.trim() !== ''">
            <a href="#" class="nav-link" @click="setSection('youtube')"
                :class="{ active: currentSection === 'youtube' }">Listen / Watch</a>
        </li>
        <li class="nav-item" v-show="currentSong.karoke && currentSong.karoke.trim() !== ''">
            <a href="#" class="nav-link" @click="setSection('karoke')"
                :class="{ active: currentSection === 'karoke' }">Karaoke</a>
        </li>
        <li class="nav-item">
            <a href="#" class="nav-link" @click="setSection('info')"
                :class="{ active: currentSection === 'info' }">Song Info</a>
        </li>
    </ul>
</template>

<script>
import { mapState } from "vuex";

export default ({
    computed: {
        ...mapState([
            'currentSection', 'currentSong', 'romanised', 
            'currentLanguage'
        ]),

        isRomanisedAvailable() {
            return this.romanised[`${ this.currentLanguage }Letters`];
        }
    },
    methods: {
        setSection(section) {
            this.$store.state.currentSection = section;
        }
    }
})
</script>

<style scoped>
.song-options {
    display: block;
    padding-right: 20px;
}
.pull-left {
    float: left;
}
</style>
