<template>
    <div class="ms_featured_slider">
        <div class="ms_heading">
            <h2>Featured Artists</h2>
            <span class="veiw_all"><router-link :to="`/${ currentLanguage }/artists`" class="artist-link">view more</router-link></span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default ({
    computed: mapState(['currentLanguage']),
})
</script>
