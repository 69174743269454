<template>
    <div class="ms_weekly_wrapper">
        <div class="ms_weekly_inner">
            <div class="row">
                <div class="col-lg-12"><div class="ms_heading"><h2>{{ title }}</h2></div></div>
                <div id="alphabetCont" class="col-lg-12 alphabet-container">
                    <router-link v-for="alphabet in sectionAlphabets" 
                        :key="alphabet" @click="showOverlay()"
                        :to="toUrl(alphabet)" 
                        :class="currentAlphabet === alphabet ? 'active' : ''">{{ alphabet }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default ({
    computed: {
        ...mapState(['currentLanguage', 'currentAlphabet', 'isOverlayActive', 'alphabets']),

        title() {
            return this.$route.path.indexOf('video-songs') !== -1 ? 'Video Songs' : 'All Songs';
        },

        sectionAlphabets() {
            return this.isVideoPage ? 
                this.alphabets[`${ this.currentLanguage }Video`] : 
                this.alphabets[this.currentLanguage];
        },

        isVideoPage() { return this.$route.path.indexOf('video-songs') !== -1; }
    },
    methods: {
        showOverlay() {
            this.$store.state.isOverlayActive = true;
        },

        toUrl(alphabet) {
            return this.isVideoPage ?
                `/${ this.currentLanguage }/video-songs/${ alphabet }/1` :
                `/${ this.currentLanguage }/${ alphabet }/1`;
        },
    }
})
</script>

<style scoped>
.alphabet-container {
    display: flex;
    flex-wrap: wrap;
}
.alphabet-container a {
    font-size: 1.2em;
    width: 2.3em;
    text-align: center;
    border: 1px solid #ff5471;
    padding: 5px 10px;
    margin: 0 10px 11px 0;
    cursor: pointer;
    border-radius: 10px;
    transition: all .3s linear;
}
.alphabet-container a.active {
    background: #ff5471;
    color: #f6f6f6;
}
</style>
